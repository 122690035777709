<template >
  <page-header-wrapper :title='this.$route.meta.title' >
    <a-spin :fullScreen="true" :spinning="totalLoading">
      <a-form-model ref='form' :model='form' layout='inline'>
        <a-form-model-item prop='name'>
          <a-input v-model='form.name' style="width: 350px" placeholder="账户名称" />
        </a-form-model-item>
        <a-form-model-item prop='mchid'>
          <a-input v-model='form.mchid' style="width: 350px" placeholder="商户号" />
        </a-form-model-item>
        <a-form-model-item prop='paymentChannelType'>
          <a-select v-model="form.paymentChannelType" placeholder="支付渠道" style="width: 250px">
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option :value="1">
              微信
            </a-select-option>
            <a-select-option :value="2">
              乐刷门店
            </a-select-option>
            <a-select-option :value="3">
              乐刷渠道商
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type='primary' @click='handleSubmit'>
            查询
          </a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click='handleReset'>
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <div>
        <div class='table-operator'>
          <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
          <a-button style="float: right;" type='primary' @click='handleNew'>添加支付渠道</a-button>
        </div>
        <s-table ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: "100%"}' :data='loadData'  >
          <span slot='paymentChannelType' slot-scope='text, record'>
            <template>
              <a-space v-if="text == '1'">
                  微信
              </a-space>
              <a-space v-if="text == '2'">
                  乐刷门店
              </a-space>
              <a-space v-if="text == '3'">
                  乐刷渠道商
              </a-space>
            </template>
          </span>
          <span slot='action' slot-scope='text, record'>
            <template>
              <a-space>
                <a @click='handleNew(record)'>编辑</a>
                 <a-divider type='vertical' />
                <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                              @confirm='handleDelete(record)'>
                  <a href='#'>删除</a>
                </a-popconfirm>

                <span v-if="record.paymentChannelType!=1">
                   <a-divider type='vertical' />
                     <a-popconfirm title='确定激活绑定吗？' ok-text='确认' cancel-text='取消'
                                   @confirm='handleBinding(record)'>
                      <a href='#'>激活绑定</a>
                    </a-popconfirm>
                  </span>
              </a-space>
            </template>
          </span>
        </s-table>
      </div>
      <a-modal title='编辑支付渠道' :destroyOnClose='true' :maskClosable='true' :visible='deliver.visible'
               @cancel='()=>this.deliver.visible=false'
               ok-text='提交' cancel-text='取消' @ok='handleDeliver' :confirmLoading='deliver.loading'>
        <a-form-model ref='form' :model='deliver.form' :rules='deliver.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
          <a-form-model-item label='账户名称:' prop='name'>
            <a-input v-model='deliver.form.name' />
          </a-form-model-item>
          <a-form-model-item label='支付渠道' prop='paymentChannelType'>
            <a-select v-model="deliver.form.paymentChannelType" placeholder="请选择">
              <a-select-option :value=1>
                微信
              </a-select-option>
              <a-select-option :value=2>
                乐刷门店
              </a-select-option>
              <a-select-option :value=3>
                乐刷渠道号
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label='appid:' prop='appid' v-if="this.deliver.form.paymentChannelType ==2">
            <a-input v-model='deliver.form.appid' />
          </a-form-model-item>

          <a-form-model-item label='密钥:' prop='secret' v-if="this.deliver.form.paymentChannelType ==2">
            <a-input v-model='deliver.form.secret' />
          </a-form-model-item>

          <a-form-model-item label='渠道号' prop='leshuaMerchantId'  v-if="this.deliver.form.paymentChannelType ==3">
            <a-select
              v-model="deliver.form.leshuaMerchantId"
              show-search
              placeholder="渠道号"
              :default-active-first-option='false'
              :filter-option='false'
              :allowClear='true'
              @search="handleChannelAccountSearch"
              @change="handleChannelAccountChange"
            >
              <a-select-option v-for='item in  channelAccountList' :key='item.id'>
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label='商户号:' prop='mchid'>
            <a-input v-model='deliver.form.mchid' />
          </a-form-model-item>
        </a-form-model>
      </a-modal>

    </a-spin>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import {
  addPaymentChannel,
  deleteAccount,
  updateAccount,
  queryPayChannel,
  deletePaymentChannel,
  channelAccount, handleBindingChannel
} from '@/api/account'
import client from '@/config/oss.config.js'

const columns = [
  {
    width: 150,
    title: '账户名称',
    dataIndex: 'name'
  },
  {
    width: 150,
    title: '支付渠道',
    dataIndex: 'paymentChannelType',
    scopedSlots: { customRender: 'paymentChannelType' }
  },
  {
    width: 150,
    title: '商户号',
    dataIndex: 'mchid'
  },
  {
    width: 150,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'Account',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      totalLoading:false,//全局加载状态
      loading: false,
      // 表头
      columns,
      form: {
        keyword: ""
      },
      channelAccountList: [],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        let params = Object.assign(parameter,this.form)
        return queryPayChannel(params).then(datum => {
            return datum
          })
      },
      deliver: { 
        loading: false,
        visible: false, 
        form: {},
        rules: {
          name: [{ required: true, message: '请填写账户名称', trigger: 'change' }],
          paymentChannelType: [{ required: true, message: '请选择支付渠道', trigger: 'change' }],
          mchid: [{ required: true, message: '请填写商户号', trigger: 'change' }],
          appid: [{ required: true, message: '请填写appid', trigger: 'change' }],
          secret: [{ required: true, message: '请填写密钥', trigger: 'change' }],
        } 
      },
    }
  },
  created() {
    this.handleChannelAccountSearch('');
  },
  methods: {
    handleNew(record) {
      this.deliver.visible = true;
      this.deliver.form = Object.assign( {
        id:record.id,
        name:record.name,
        appid:record.appid,
        secret:record.secret,
        paymentChannelType:record.paymentChannelType,
        mchid:record.mchid,
        leshuaMerchantId:record.leshuaMerchantId,
      });
    },
    handleDeliver() {
      this.$refs.form.validate(result => {
       if (result) {
        this.deliver.loading = true;
        let params = Object.assign(this.deliver.form);
        debugger;
         addPaymentChannel(params).then(() => {
          this.$message.success('操作成功')
          this.deliver.visible = false;
          this.$refs.table.refresh(true)
          this.deliver.loading = false;
        }).catch(() => {
          this.deliver.loading = false;
        })
       }
      })
    },
    handleDelete(record) {
      deletePaymentChannel({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    //重新绑定
    handleBinding(record){
      this.totalLoading = true;
      handleBindingChannel({id: record.id}).then(result=>{
          this.totalLoading = false;
          this.$message.success('绑定操作成功')
          // this.$refs.table.refresh(true)
      }).catch(e=>{
          this.totalLoading = false;
      })
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
    handleReset() {
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleChannelAccountSearch(name){
      let param = {current: 1, size: 999,name:name};
      // if(this.deliver.form.paymentChannelType){
      //   param.paymentChannelType = this.deliver.form.paymentChannelType;
      // }
      channelAccount(param).then(datum => {
        // for(let item of datum.records){
        //   item.weixinSubMchid = item.mchid
        // }
        this.channelAccountList = datum.records;
      })
    },
    handleChannelAccountChange(e) {
      this.$set(this.deliver.form, "leshuaMerchantId", e);
    },
  }
}
</script>
